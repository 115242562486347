import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import startOfToday from 'date-fns/startOfToday';
import { Formik, useFormikContext } from 'formik';
import last from 'lodash/last';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isValid from 'date-fns/isValid';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SessionsField from 'src/components/Demands/SessionsField';
import { TRATAMIENTO_QUIMIOTERAPICO_INTENCION } from 'src/constants/demands';
import { DECIMAL_VALIDATOR } from 'src/constants/regex';
import { useListDrugs } from 'src/hooks/drugs';
import { useDebouncedCallback } from 'use-debounce/lib';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as Yup from 'yup';
import DrugsField from './components/DrugsField';
import RadioterapicoField from './components/RadioterapicoField';
import CustomSwitch from 'src/components/CustomSwitch';
import { useListCodes } from 'src/hooks/codes';

const getSupCorporal = (weight, height) => {
  const supCorporal = Math.pow((weight * height) / 3600, 0.5);

  // Round to 2 decimals
  return Math.round(supCorporal * 100) / 100;
};

const getDiasCiclo = (frecuenciaSesiones, nSesionesCiclo) => {
  if (!nSesionesCiclo) {
    return frecuenciaSesiones;
  }

  return frecuenciaSesiones * (nSesionesCiclo - 1) + 1;
};

const FORM_DEPENDANTS = {
  tratamientoQuimioterapico: [
    'quimioterapicoIntencion',
    'quimioterapicoTalla',
    'quimioterapicoPeso',
    'quimioterapicoCiclo',
    'quimioterapicoNumeroCiclos',
    'quimioterapicoNumeroSesionesCiclo',
    'quimioterapicoFrecuenciaSesiones',
    'quimioterapicoIntervaloCiclos',
    'quimioterapicoFechaPrimeraSesion',
    'quimioterapicoFarmacos',
    'quimioterapicoAntiemeticos',
    'quimioterapicoHeparinizacion',
    'quimioterapicoImplantacionInfusor',
    'quimioterapicoRetiradaInfusor',
    'quimioterapicoAntihistaminicos',
    'quimioterapicoCorticoides',
    'quimioterapicoEritropoyetinas',
    'quimioterapicoOtrosBifosfonatos',
    'quimioterapicoEnsayoClinico',
    'quimioterapicoFechasSesiones',
  ],
  tratamientoRadioterapico: ['tratamientosRadioterapicos'],
};
const OPTIONAL_FIELDS = [
  'quimioterapicoAntiemeticos',
  'quimioterapicoImplantacionInfusor',
  'quimioterapicoRetiradaInfusor',
  'quimioterapicoAntihistaminicos',
  'quimioterapicoCorticoides',
  'quimioterapicoEritropoyetinas',
  'quimioterapicoOtrosBifosfonatos',
  // 'quimioterapicoFarmacos', // Special one, as it's an array
];

const FormContext = ({
  isFullfilled,
  activeTreatments,
  setProposedTreatments,
  setDisableNext,
  isContinue,
  isChangeDates,
}) => {
  const { values, setFieldValue, touched, errors, dirty, isValid, submitForm } = useFormikContext();
  const { callback: debounceUpdate } = useDebouncedCallback(() => updateDemand(), 600);
  const { callback: debounceDisable } = useDebouncedCallback(() => updateDisableNext(), 600);

  useEffect(() => {
    if (!isContinue && !isChangeDates) {
      if (activeTreatments?.quimioterapiaFarmacos) {
        setFieldValue('quimioterapicoFarmacos', []);
      }
      if (activeTreatments?.radioterapia) {
        setFieldValue('tratamientoRadioterapico', false);
        setFieldValue('tratamientosRadioterapicos', []);
      }
    }
  }, [activeTreatments, isChangeDates, isContinue, setFieldValue]);

  const updateDisableNext = () => {
    setDisableNext(isFullfilled ? !isValid : !(dirty && isValid));
  };

  const updateDemand = () => {
    const errorsArray = Object.getOwnPropertyNames(errors);

    const valuesToPick = [];

    // Check if any of the fields dependants has errors. In that case, omit the field and the dependants
    const dependants = Object.getOwnPropertyNames(FORM_DEPENDANTS);
    dependants.forEach((field) => {
      if (values[field]) {
        let dependantError = false;

        FORM_DEPENDANTS[field].forEach((dependant) => {
          if (errorsArray.includes(dependant)) {
            dependantError = true;
          }
        });

        if (!dependantError) {
          valuesToPick.push(field, ...FORM_DEPENDANTS[field]);
        }
      } else {
        if (values.tratamientoQuimioterapico || values.tratamientoRadioterapico) {
          valuesToPick.push(field);
        }
      }
    });

    const data = omit(pick(values, valuesToPick), errorsArray);

    if (data.quimioterapicoFarmacos) {
      data.quimioterapicoFarmacos = data.quimioterapicoFarmacos.filter(
        (drug, index) => touched.quimioterapicoFarmacos[index]?.drugId,
      );
    }
    if (data.tratamientosRadioterapicos) {
      data.tratamientosRadioterapicos = data.tratamientosRadioterapicos.filter((treatment, index) =>
        get(touched, `tratamientosRadioterapicos[${index}].intencion`),
      );
    }

    // Format dates
    if (data.quimioterapicoFechaPrimeraSesion) {
      data.quimioterapicoFechaPrimeraSesion = format(
        new Date(data.quimioterapicoFechaPrimeraSesion),
        'yyyy-MM-dd',
      );
    }
    if (data.quimioterapicoImplantacionInfusor) {
      data.quimioterapicoImplantacionInfusor = format(
        new Date(data.quimioterapicoImplantacionInfusor),
        'yyyy-MM-dd',
      );
    }
    if (data.quimioterapicoRetiradaInfusor) {
      data.quimioterapicoRetiradaInfusor = format(
        new Date(data.quimioterapicoRetiradaInfusor),
        'yyyy-MM-dd',
      );
    }
    if (data.tratamientosRadioterapicos && data.tratamientosRadioterapicos.length) {
      data.tratamientosRadioterapicos = data.tratamientosRadioterapicos.map((treatment) => ({
        ...treatment,
        fechaInicio: format(new Date(treatment.fechaInicio), 'yyyy-MM-dd'),
      }));
    }
    if (data.quimioterapicoFechasSesiones && data.quimioterapicoFechasSesiones.length) {
      data.quimioterapicoFechasSesiones = data.quimioterapicoFechasSesiones.map((date) =>
        format(new Date(date), 'yyyy-MM-dd'),
      );
    }

    OPTIONAL_FIELDS.forEach((field) => {
      if (data[field] === '') {
        data[field] = null;
      }
    });

    if (data.hasOwnProperty('tratamientoRadioterapico') && !data.tratamientoRadioterapico) {
      data.tratamientosRadioterapicos = [];
    }

    setProposedTreatments(data);
  };

  // If user has already fullfilled the form and came back to this step, submit the form to show validation errors
  useEffect(() => {
    if (isFullfilled) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable "next" button if form has errors or is not dirty
  useEffect(() => {
    if (isValid) {
      debounceDisable();
    } else {
      updateDisableNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceDisable, isFullfilled, isValid]);

  // Update demand on every field change
  useEffect(() => {
    debounceUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, setProposedTreatments, values]);

  return null;
};

const Step4Form = ({
  initialValues,
  setProposedTreatments,
  activeTreatments,
  isFullfilled,
  disableForm,
  setDisableNext,
  isContinue,
  isChangeDates,
  shouldBeContinue,
  originalFirstSessionDate,
}) => {
  const { t } = useTranslation(['demands', 'common']);

  const { isLoading: isLoadingDrugs, resolvedData: drugs, isError: isErrorDrugs, refetch: refetchDrugs } = useListDrugs({
    all: true,
    sort: 'name'
  });

  const codesQuery = useListCodes({ all: true });
  
  const validationSchema = Yup.object().shape({
    tratamientoQuimioterapico: Yup.bool()
      .required(t('errors.tratamientoQuimioterapico.required'))
      .test('oneOfRequired', t('errors.oneOfRequired'), function () {
        return this.parent.tratamientoQuimioterapico || this.parent.tratamientoRadioterapico;
      }),
    quimioterapicoIntencion: Yup.string().when('tratamientoQuimioterapico', (type, schema) =>
      type ? schema.required(t('errors.quimioterapicoIntencion.required')) : schema,
    ),
    quimioterapicoTalla: Yup.number()
      .nullable()
      .integer(t('errors.quimioterapicoTalla.integer'))
      .positive(t('errors.quimioterapicoTalla.positive'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('common:errors.number_required')) : schema,
      ),
    quimioterapicoPeso: Yup.number()
      .nullable()
      .positive(t('errors.quimioterapicoPeso.positive'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type
          ? schema
              .required(t('common:errors.number_required'))
              .test('is-decimal', t('errors.quimioterapicoPeso.decimal'), (value) =>
                String(value).match(DECIMAL_VALIDATOR),
              )
          : schema,
      ),
    quimioterapicoCiclo: Yup.number()
      .nullable()
      .integer(t('errors.quimioterapicoCiclo.integer'))
      .min(0, t('errors.quimioterapicoCiclo.min'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('common:errors.number_required')) : schema,
      ),
    quimioterapicoNumeroCiclos: Yup.number()
      .nullable()
      .integer(t('errors.quimioterapicoNumeroCiclos.integer'))
      .min(0, t('errors.quimioterapicoNumeroCiclos.min'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('common:errors.number_required')) : schema,
      ),
    quimioterapicoNumeroSesionesCiclo: Yup.number()
      .nullable()
      .integer(t('errors.quimioterapicoNumeroSesionesCiclo.integer'))
      .min(0, t('errors.quimioterapicoNumeroSesionesCiclo.min'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('common:errors.number_required')) : schema,
      ),
    quimioterapicoFrecuenciaSesiones: Yup.number()
      .nullable()
      .integer(t('errors.quimioterapicoFrecuenciaSesiones.integer'))
      .min(0, t('errors.quimioterapicoFrecuenciaSesiones.min'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('common:errors.number_required')) : schema,
      ),
    quimioterapicoIntervaloCiclos: Yup.number()
      .nullable()
      .integer(t('errors.quimioterapicoIntervaloCiclos.integer'))
      .min(0, t('errors.quimioterapicoIntervaloCiclos.min'))
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('common:errors.number_required')) : schema,
      ),
    quimioterapicoFechaPrimeraSesion: shouldBeContinue
      ? Yup.date()
          .typeError(t('errors.quimioterapicoFechaPrimeraSesion.date_format'))
          .nullable()
          .when('tratamientoQuimioterapico', (type, schema) =>
            type ? schema.required(t('errors.quimioterapicoFechaPrimeraSesion.required')) : schema,
          )
      : Yup.date()
          .typeError(t('errors.quimioterapicoFechaPrimeraSesion.date_format'))
          //.min(startOfToday(), t('errors.quimioterapicoFechaPrimeraSesion.min'))
          .nullable()
          .when('tratamientoQuimioterapico', (type, schema) =>
            type ? schema.required(t('errors.quimioterapicoFechaPrimeraSesion.required')) : schema,
          ),
    quimioterapicoFarmacos: Yup.array()
      .of(
        Yup.object().shape({
          drugId: Yup.number().required(t('errors.quimioterapicoFarmacos_values.farmaco.required')),
          dosificacion: Yup.number()
            .nullable()
            .min(0, t('errors.quimioterapicoFarmacos_values.dosificacion.min'))
            .required(t('common:errors.number_required'))
            .test(
              'is-decimal',
              t('errors.quimioterapicoFarmacos_values.dosificacion.decimal'),
              (value) => String(value).match(DECIMAL_VALIDATOR),
            ),
          tipoDosificacion: Yup.string().required(
            t('errors.quimioterapicoFarmacos_values.tipoDosificacion.required'),
          ),
          formaAdministracion: Yup.string().required(
            t('errors.quimioterapicoFarmacos_values.formaAdministracion.required'),
          ),
          administrarEnSesiones: Yup.array()
            .of(Yup.number().nullable())
            .required(t('errors.quimioterapicoFarmacos_values.administrarEnSesiones.required')),
          presentationId: Yup.number().required(
            t('errors.quimioterapicoFarmacos_values.presentation.required'),
          ),
          price: Yup.string().nullable(),
          //amount: Yup.string().required(t('errors.quimioterapicoFarmacos_values.amount.required')),
        }),
      )
      .test('chemotherapyTreatment', t('errors.chemotherapyTreatment'), function () {
        if (!this.parent.tratamientoQuimioterapico) {
          // Omit validation
          return true;
        }

        return (
          this.parent.quimioterapicoFarmacos.length ||
          this.parent.quimioterapicoImplantacionInfusor ||
          this.parent.quimioterapicoRetiradaInfusor ||
          this.parent.quimioterapicoHeparinizacion
        );
      }),
    quimioterapicoAntiemeticos: Yup.string(),
    quimioterapicoHeparinizacion: Yup.bool()
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('errors.quimioterapicoHeparinizacion.required')) : schema,
      )
      .test('chemotherapyTreatment', t('errors.chemotherapyTreatment'), function () {
        if (!this.parent.tratamientoQuimioterapico) {
          // Omit validation
          return true;
        }

        return (
          this.parent.quimioterapicoFarmacos.length ||
          this.parent.quimioterapicoImplantacionInfusor ||
          this.parent.quimioterapicoRetiradaInfusor ||
          this.parent.quimioterapicoHeparinizacion
        );
      }),
    quimioterapicoImplantacionInfusor: Yup.date()
      .typeError(t('errors.quimioterapicoImplantacionInfusor.date_format'))
      .nullable()
      .test('chemotherapyTreatment', t('errors.chemotherapyTreatment'), function () {
        if (!this.parent.tratamientoQuimioterapico) {
          // Omit validation
          return true;
        }

        return (
          this.parent.quimioterapicoFarmacos.length ||
          this.parent.quimioterapicoImplantacionInfusor ||
          this.parent.quimioterapicoRetiradaInfusor ||
          this.parent.quimioterapicoHeparinizacion
        );
      }),
    quimioterapicoRetiradaInfusor: Yup.date()
      .typeError(t('errors.quimioterapicoRetiradaInfusor.date_format'))
      .nullable()
      .test('chemotherapyTreatment', t('errors.chemotherapyTreatment'), function () {
        if (!this.parent.tratamientoQuimioterapico) {
          // Omit validation
          return true;
        }

        return (
          this.parent.quimioterapicoFarmacos.length ||
          this.parent.quimioterapicoImplantacionInfusor ||
          this.parent.quimioterapicoRetiradaInfusor ||
          this.parent.quimioterapicoHeparinizacion
        );
      }),
    quimioterapicoAntihistaminicos: Yup.string(),
    quimioterapicoCorticoides: Yup.string(),
    quimioterapicoEritropoyetinas: Yup.string(),
    quimioterapicoOtrosBifosfonatos: Yup.string(),
    quimioterapicoEnsayoClinico: Yup.bool().when('tratamientoQuimioterapico', (type, schema) =>
      type ? schema.required(t('errors.quimioterapicoEnsayoClinico.required')) : schema,
    ),
    quimioterapicoFechasSesiones: Yup.array()
      .of(
        Yup.date()
          .typeError(t('errors.quimioterapicoFechasSesiones.date_format'))
          .required(t('errors.quimioterapicoFechasSesiones.required'))
          .test('session-date-first', t('errors.quimioterapicoFechasSesiones.min_first'), function (
            value,
          ) {
            const firstSessionDate = get(this.from, '[0].value.quimioterapicoFechaPrimeraSesion');

            if (
              isSameDay(value, new Date(firstSessionDate)) ||
              isAfter(value, new Date(firstSessionDate))
            ) {
              return true;
            }

            return false;
          })
          .test(
            'session-date-min',
            t('errors.quimioterapicoFechasSesiones.min_previous'),
            function (value) {
              const sessionIndex = this.parent.indexOf(value);
              const prevSessionDate = this.parent[sessionIndex - 1];

              if (!prevSessionDate || isAfter(value, new Date(prevSessionDate))) {
                return true;
              }

              return false;
            },
          )
          .test('session-date-max', t('errors.quimioterapicoFechasSesiones.max_next'), function (
            value,
          ) {
            const sessionIndex = this.parent.indexOf(value);
            const nextSessionDate = this.parent[sessionIndex + 1];

            if (!nextSessionDate || isBefore(value, new Date(nextSessionDate))) {
              return true;
            }

            return false;
          }),
      )
      .when('tratamientoQuimioterapico', (type, schema) =>
        type ? schema.required(t('errors.quimioterapicoFechasSesiones.required')) : schema,
      ),

    tratamientoRadioterapico: Yup.bool()
      .required(t('errors.tratamientoRadioterapico.required'))
      .test('oneOfRequired', t('errors.oneOfRequired'), function () {
        return this.parent.tratamientoQuimioterapico || this.parent.tratamientoRadioterapico;
      }),
    tratamientosRadioterapicos: Yup.array()
      .of(
        Yup.object().shape({
          intencion: Yup.string().required(
            t('errors.tratamientosRadioterapicos_values.intencion.required'),
          ),
          codigo: Yup.number()
                  .required(t('errors.tratamientosRadioterapicos_values.codigo.required')),
          descripcionDetallada: Yup.string().required(
            t('errors.tratamientosRadioterapicos_values.descripcionDetallada.required'),
          ),
          dosisTotal: Yup.number()
            .min(0, t('errors.tratamientosRadioterapicos_values.dosisTotal.min'))
            .required(t('common:errors.number_required'))
            .test(
              'is-decimal',
              t('errors.tratamientosRadioterapicos_values.dosisTotal.decimal'),
              (value) => String(value).match(DECIMAL_VALIDATOR),
            ),
          fraccionamientoDia: Yup.number()
            .min(0, t('errors.tratamientosRadioterapicos_values.fraccionamientoDia.min'))
            .integer(t('errors.tratamientosRadioterapicos_values.fraccionamientoDia.integer'))
            .required(t('common:errors.number_required')),
          fechaInicio: Yup.date()
            .typeError(t('errors.tratamientosRadioterapicos_values.fechaInicio.date_format'))
            .min(
              isContinue || isChangeDates ? new Date(0) : startOfToday(),
              t('errors.tratamientosRadioterapicos_values.fechaInicio.min'),
            )
            .required(t('errors.tratamientosRadioterapicos_values.fechaInicio.required')),
          numeroSesiones: Yup.number()
            .integer(t('errors.tratamientosRadioterapicos_values.numeroSesiones.integer'))
            .min(0, t('errors.tratamientosRadioterapicos_values.numeroSesiones.min'))
            .required(t('common:errors.number_required')),
        }),
      )
      .when('tratamientoRadioterapico', (type, schema) =>
        type ? schema.required(t('errors.tratamientosRadioterapicos.required')) : schema,
      ),
  });

  const handleSubmit = (values, formikBag) => {
    formikBag.setSubmitting(false);
  };

  const removeDrugsFromSessions = (e, values, setFieldValue) => {
    const previousValue = values.quimioterapicoNumeroSesionesCiclo;
    const newValue = e.target.value;

    if (newValue < previousValue) {
      const drugs = values.quimioterapicoFarmacos.map((drug) => {
        const administrarEnSesiones = drug.administrarEnSesiones.filter(
          (session) => session <= newValue,
        );
        return { ...drug, administrarEnSesiones };
      });

      setFieldValue('quimioterapicoFarmacos', drugs);
    }
  };

  const getDateForSession = (session, values, dateFirstSession) => {
    const firstSessionDate = dateFirstSession || values.quimioterapicoFechaPrimeraSesion;
    const sessionsFrecuency = values.quimioterapicoFrecuenciaSesiones || 1;

    if (!firstSessionDate) {
      return '';
    }

    const sessionsPassed = session - 1;
    const currentSessionDate = sessionsPassed * sessionsFrecuency;

    return format(addDays(new Date(firstSessionDate), currentSessionDate), 'yyyy-MM-dd');
  };

  const getNextSessionDate = (lastSessionDate, values) => {
    const firstSessionDate = values.quimioterapicoFechaPrimeraSesion || 1;
    const sessionsFrencuency = values.quimioterapicoFrecuenciaSesiones || 1;

    if (!lastSessionDate || !firstSessionDate) {
      return '';
    }

    return format(
      addDays(new Date(lastSessionDate || firstSessionDate), sessionsFrencuency),
      'yyyy-MM-dd',
    );
  };

  const getSessionsDates = (values, firstSessionDate) => {
    const sessions = values.quimioterapicoNumeroSesionesCiclo;

    const sessionsDates = [];
    if (sessions) {
      for (let session = 1; session <= sessions; session++) {
        sessionsDates.push(getDateForSession(session, values, firstSessionDate));
      }
    }

    return sessionsDates;
  };

  const addRemoveSessionsDates = (values, newSessions, setFieldValue) => {
    const prevSessions =
      values.quimioterapicoNumeroSesionesCiclo || values.quimioterapicoFechasSesiones.length;

    if (!newSessions) {
      return;
    }

    if (prevSessions < newSessions) {
      // Add dates
      const sessionsDates = [...values.quimioterapicoFechasSesiones];
      for (let session = prevSessions + 1; session <= newSessions; session++) {
        sessionsDates.push(getNextSessionDate(last(sessionsDates), values));
      }
      setFieldValue('quimioterapicoFechasSesiones', sessionsDates);
    }
    if (prevSessions > newSessions) {
      // Remove dates
      const sessionsDates = [...values.quimioterapicoFechasSesiones];
      sessionsDates.splice(newSessions, prevSessions - newSessions);
      setFieldValue('quimioterapicoFechasSesiones', sessionsDates);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        setFieldTouched,
        handleChange,
        setFieldValue,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    name="tratamientoQuimioterapico"
                    checked={values.tratamientoQuimioterapico}
                    onChange={(e) => {
                      setFieldTouched('tratamientoQuimioterapico', true);
                      return handleChange(e);
                    }}
                    onBlur={handleBlur}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                  />
                }
                label={t('fields.tratamientoQuimioterapico')}
                labelPlacement="start"
              />
            </Grid>
            {values.tratamientoQuimioterapico && (
              <>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.quimioterapicoIntencion && errors.quimioterapicoIntencion,
                    )}
                    fullWidth
                    required
                    helperText={touched.quimioterapicoIntencion && errors.quimioterapicoIntencion}
                    select
                    SelectProps={{ native: true }}
                    label={t('fields.quimioterapicoIntencion')}
                    name="quimioterapicoIntencion"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoIntencion}
                    variant="outlined"
                  >
                    <option value="" hidden></option>
                    <option value={TRATAMIENTO_QUIMIOTERAPICO_INTENCION.NEO_ADYUVANTE}>
                      {t('fields.quimioterapicoIntencion_options.neo_adyuvante')}
                    </option>
                    <option value={TRATAMIENTO_QUIMIOTERAPICO_INTENCION.ADYUVANTE}>
                      {t('fields.quimioterapicoIntencion_options.adyuvante')}
                    </option>
                    <option value={TRATAMIENTO_QUIMIOTERAPICO_INTENCION.PALIATIVO}>
                      {t('fields.quimioterapicoIntencion_options.paliativo')}
                    </option>
                    <option value={TRATAMIENTO_QUIMIOTERAPICO_INTENCION.CURATIVO}>
                      {t('fields.quimioterapicoIntencion_options.curativo')}
                    </option>
                    <option value={TRATAMIENTO_QUIMIOTERAPICO_INTENCION.RADICAL}>
                      {t('fields.quimioterapicoIntencion_options.radical')}
                    </option>
                    <option value={TRATAMIENTO_QUIMIOTERAPICO_INTENCION.NO_SE_INDICA}>
                      {t('fields.quimioterapicoIntencion_options.no_se_indica')}
                    </option>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="number"
                    inputProps={{ min: 1, step: '1' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                    }}
                    error={Boolean(touched.quimioterapicoTalla && errors.quimioterapicoTalla)}
                    fullWidth
                    required
                    helperText={touched.quimioterapicoTalla && errors.quimioterapicoTalla}
                    label={t('fields.quimioterapicoTalla')}
                    name="quimioterapicoTalla"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoTalla}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    type="number"
                    inputProps={{ min: 0.01, step: '.01' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                    }}
                    error={Boolean(touched.quimioterapicoPeso && errors.quimioterapicoPeso)}
                    fullWidth
                    required
                    helperText={touched.quimioterapicoPeso && errors.quimioterapicoPeso}
                    label={t('fields.quimioterapicoPeso')}
                    name="quimioterapicoPeso"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoPeso}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    InputProps={{
                      readOnly: true,
                      endAdornment: <InputAdornment position="end">m2</InputAdornment>,
                    }}
                    fullWidth
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    label={t('fields.quimioterapicoSupCorporal')}
                    value={getSupCorporal(values.quimioterapicoPeso, values.quimioterapicoTalla)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box display="flex">
                    <TextField
                      type="number"
                      inputProps={{ min: 0, step: '1' }}
                      error={Boolean(touched.quimioterapicoCiclo && errors.quimioterapicoCiclo)}
                      fullWidth
                      required
                      helperText={touched.quimioterapicoCiclo && errors.quimioterapicoCiclo}
                      label={t('fields.quimioterapicoCiclo')}
                      name="quimioterapicoCiclo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                      value={
                        isContinue && !shouldBeContinue
                          ? values.quimioterapicoCiclo + 1
                          : values.quimioterapicoCiclo
                      }
                      variant="outlined"
                    />
                    <Box display="flex" justifyContent="center" mt={2} mx={2}>
                      <Typography>{t('fields.quimioterapicoCicloPreposition')}</Typography>
                    </Box>
                    <TextField
                      type="number"
                      inputProps={{ min: 0, step: '1' }}
                      error={Boolean(
                        touched.quimioterapicoNumeroCiclos && errors.quimioterapicoNumeroCiclos,
                      )}
                      fullWidth
                      required
                      helperText={
                        touched.quimioterapicoNumeroCiclos && errors.quimioterapicoNumeroCiclos
                      }
                      label={t('fields.quimioterapicoNumeroCiclos')}
                      name="quimioterapicoNumeroCiclos"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                      value={values.quimioterapicoNumeroCiclos}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    type="number"
                    inputProps={{ min: 0, step: '1' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('labels.days')}</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.quimioterapicoIntervaloCiclos && errors.quimioterapicoIntervaloCiclos,
                    )}
                    fullWidth
                    required
                    helperText={
                      touched.quimioterapicoIntervaloCiclos && errors.quimioterapicoIntervaloCiclos
                    }
                    label={t('fields.quimioterapicoIntervaloCiclos')}
                    name="quimioterapicoIntervaloCiclos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoIntervaloCiclos}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="number"
                    inputProps={{ min: 0, step: '1' }}
                    error={Boolean(
                      touched.quimioterapicoNumeroSesionesCiclo &&
                        errors.quimioterapicoNumeroSesionesCiclo,
                    )}
                    fullWidth
                    required
                    helperText={
                      touched.quimioterapicoNumeroSesionesCiclo &&
                      errors.quimioterapicoNumeroSesionesCiclo
                    }
                    label={t('fields.quimioterapicoNumeroSesionesCiclo')}
                    name="quimioterapicoNumeroSesionesCiclo"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      removeDrugsFromSessions(e, values, setFieldValue);
                      addRemoveSessionsDates(values, e.target.value, setFieldValue);

                      handleChange(e);
                    }}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoNumeroSesionesCiclo}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="number"
                    inputProps={{ min: 0, step: '1' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{t('labels.days')}</InputAdornment>
                      ),
                    }}
                    error={Boolean(
                      touched.quimioterapicoFrecuenciaSesiones &&
                        errors.quimioterapicoFrecuenciaSesiones,
                    )}
                    fullWidth
                    required
                    helperText={
                      touched.quimioterapicoFrecuenciaSesiones &&
                      errors.quimioterapicoFrecuenciaSesiones
                    }
                    label={t('fields.quimioterapicoFrecuenciaSesiones')}
                    name="quimioterapicoFrecuenciaSesiones"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoFrecuenciaSesiones}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    InputProps={{ readOnly: true }}
                    fullWidth
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    label={t('fields.quimioterapicoDiasCiclo')}
                    value={getDiasCiclo(
                      values.quimioterapicoFrecuenciaSesiones,
                      values.quimioterapicoNumeroSesionesCiclo,
                    )}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <KeyboardDatePicker
                    fullWidth
                    required
                    disableToolbar
                    format="dd/MM/yyyy"
                    minDate={shouldBeContinue ? originalFirstSessionDate : "2000-01-01"}
                    label={t('fields.quimioterapicoFechaPrimeraSesion')}
                    error={Boolean(
                      touched.quimioterapicoFechaPrimeraSesion &&
                        errors.quimioterapicoFechaPrimeraSesion,
                    )}
                    helperText={
                      touched.quimioterapicoFechaPrimeraSesion &&
                      errors.quimioterapicoFechaPrimeraSesion
                    }
                    value={values.quimioterapicoFechaPrimeraSesion}
                    onChange={(date) => {
                      setFieldValue('quimioterapicoFechaPrimeraSesion', date);
                      setTimeout(() => {
                        setFieldTouched('quimioterapicoFechaPrimeraSesion', true);
                      }, 0);

                      if (date && isValid(new Date(date))) {
                        setFieldValue(
                          'quimioterapicoFechasSesiones',
                          getSessionsDates(values, date),
                        );
                      }
                    }}
                    onBlur={() => setFieldTouched('quimioterapicoFechaPrimeraSesion', true)}
                    disabled={disableForm || isSubmitting}
                    variant="inline"
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  {!isContinue && !isChangeDates && activeTreatments?.quimioterapiaFarmacos && (
                    <Grid item xs={12}>
                      <Box m={2}>
                        <FormHelperText error={true}>
                          {t('errors.active_chemo_treatment')}
                        </FormHelperText>
                      </Box>
                    </Grid>
                  )}
                  
                  <DrugsField
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    disabled={
                      disableForm || isSubmitting || activeTreatments?.quimioterapiaFarmacos
                    }
                    isLoading={isLoadingDrugs}
                    drugs={drugs?.data}
                    refetchDrugs={refetchDrugs}
                    isError={isErrorDrugs}
                    isContinue={isContinue}
                    isChangeDates={isChangeDates}
                    drugsTreatmentActive={activeTreatments?.quimioterapiaFarmacos}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.quimioterapicoAntiemeticos && errors.quimioterapicoAntiemeticos,
                    )}
                    fullWidth
                    helperText={
                      touched.quimioterapicoAntiemeticos && errors.quimioterapicoAntiemeticos
                    }
                    label={t('fields.quimioterapicoAntiemeticos')}
                    name="quimioterapicoAntiemeticos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoAntiemeticos}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        name="quimioterapicoHeparinizacion"
                        checked={values.quimioterapicoHeparinizacion}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                      />
                    }
                    label={t('fields.quimioterapicoHeparinizacion')}
                    labelPlacement="start"
                  />

                  {touched.quimioterapicoHeparinizacion && errors.quimioterapicoHeparinizacion && (
                    <Box ml={2}>
                      <FormHelperText error={true}>
                        {errors.quimioterapicoHeparinizacion}
                      </FormHelperText>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    format="dd/MM/yyyy"
                    InputLabelProps={{
                      shrink: !!values.quimioterapicoImplantacionInfusor,
                    }}
                    label={t('fields.quimioterapicoImplantacionInfusor')}
                    error={Boolean(
                      touched.quimioterapicoImplantacionInfusor &&
                        errors.quimioterapicoImplantacionInfusor,
                    )}
                    helperText={
                      touched.quimioterapicoImplantacionInfusor &&
                      errors.quimioterapicoImplantacionInfusor
                    }
                    value={values.quimioterapicoImplantacionInfusor}
                    onChange={(date) => {
                      setFieldTouched('quimioterapicoImplantacionInfusor', true);
                      setFieldValue('quimioterapicoImplantacionInfusor', date);
                    }}
                    onBlur={() => setFieldTouched('quimioterapicoImplantacionInfusor', true)}
                    disabled={disableForm || isSubmitting}
                    variant="inline"
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    format="dd/MM/yyyy"
                    InputLabelProps={{
                      shrink: !!values.quimioterapicoRetiradaInfusor,
                    }}
                    label={t('fields.quimioterapicoRetiradaInfusor')}
                    error={Boolean(
                      touched.quimioterapicoRetiradaInfusor && errors.quimioterapicoRetiradaInfusor,
                    )}
                    helperText={
                      touched.quimioterapicoRetiradaInfusor && errors.quimioterapicoRetiradaInfusor
                    }
                    value={values.quimioterapicoRetiradaInfusor}
                    onChange={(date) => {
                      setFieldTouched('quimioterapicoRetiradaInfusor', true);
                      setFieldValue('quimioterapicoRetiradaInfusor', date);
                    }}
                    onBlur={() => setFieldTouched('quimioterapicoRetiradaInfusor', true)}
                    disabled={disableForm || isSubmitting}
                    variant="inline"
                    inputVariant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.quimioterapicoAntihistaminicos &&
                        errors.quimioterapicoAntihistaminicos,
                    )}
                    fullWidth
                    helperText={
                      touched.quimioterapicoAntihistaminicos &&
                      errors.quimioterapicoAntihistaminicos
                    }
                    label={t('fields.quimioterapicoAntihistaminicos')}
                    name="quimioterapicoAntihistaminicos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoAntihistaminicos}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.quimioterapicoCorticoides && errors.quimioterapicoCorticoides,
                    )}
                    fullWidth
                    helperText={
                      touched.quimioterapicoCorticoides && errors.quimioterapicoCorticoides
                    }
                    label={t('fields.quimioterapicoCorticoides')}
                    name="quimioterapicoCorticoides"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoCorticoides}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.quimioterapicoEritropoyetinas && errors.quimioterapicoEritropoyetinas,
                    )}
                    fullWidth
                    helperText={
                      touched.quimioterapicoEritropoyetinas && errors.quimioterapicoEritropoyetinas
                    }
                    label={t('fields.quimioterapicoEritropoyetinas')}
                    name="quimioterapicoEritropoyetinas"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoEritropoyetinas}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(
                      touched.quimioterapicoOtrosBifosfonatos &&
                        errors.quimioterapicoOtrosBifosfonatos,
                    )}
                    fullWidth
                    helperText={
                      touched.quimioterapicoOtrosBifosfonatos &&
                      errors.quimioterapicoOtrosBifosfonatos
                    }
                    label={t('fields.quimioterapicoOtrosBifosfonatos')}
                    name="quimioterapicoOtrosBifosfonatos"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                    value={values.quimioterapicoOtrosBifosfonatos}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        name="quimioterapicoEnsayoClinico"
                        checked={values.quimioterapicoEnsayoClinico}
                        onChange={handleChange}
                        disabled={disableForm || isSubmitting || isContinue || isChangeDates}
                      />
                    }
                    label={t('fields.quimioterapicoEnsayoClinico')}
                    labelPlacement="start"
                  />
                </Grid>

                <Grid item xs={12}>
                  <SessionsField
                    edit={true}
                    values={values}
                    errors={errors}
                    touched={touched}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    disabled={disableForm || isSubmitting}
                    drugs={drugs?.data}
                  />
                </Grid>
              </>
            )}

            {activeTreatments?.radioterapia && (
              <Grid item xs={12}>
                <Box ml={2}>
                  <FormHelperText error={true}>{t('errors.active_radio_treatment')}</FormHelperText>
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <RadioterapicoField
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                codes={codesQuery.data?.data.rows}
                isLoading={codesQuery.isLoading}
                disabled={
                  disableForm ||
                  isSubmitting ||
                  isContinue ||
                  activeTreatments?.radioterapia ||
                  isChangeDates
                }
              />
            </Grid>

            {(touched.tratamientoQuimioterapico || touched.tratamientoRadioterapico) &&
              !values.tratamientoQuimioterapico &&
              !values.tratamientoRadioterapico && (
                <Grid item xs={12}>
                  <Box ml={2}>
                    <FormHelperText error>{t('errors.oneOfRequired')}</FormHelperText>
                  </Box>
                </Grid>
              )}
          </Grid>

          <FormContext
            isFullfilled={isFullfilled}
            activeTreatments={activeTreatments}
            setProposedTreatments={setProposedTreatments}
            setDisableNext={setDisableNext}
            isContinue={isContinue}
            isChangeDates={isChangeDates}
          />
        </form>
      )}
    </Formik>
  );
};

Step4Form.propTypes = {
  initialValues: PropTypes.object,
  isFullfilled: PropTypes.bool,
  setProposedTreatments: PropTypes.func,
  activeTreatments: PropTypes.object,
  disableForm: PropTypes.bool,
  setDisableNext: PropTypes.func,
  isContinue: PropTypes.bool,
  isChangeDates: PropTypes.bool,
  shouldBeContinue: PropTypes.bool,
  originalFirstSessionDate: PropTypes.string,
};

export default Step4Form;
