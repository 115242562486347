import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import format from 'date-fns/format';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CustomSwitch from 'src/components/CustomSwitch';
import {
  isAssistant as isAssistantSelector,
  isDoctor as isDoctorSelector,
} from 'src/store/selectors/user';
import { getDemandStatus } from 'src/utils/models/transforms/demand';

const useStyles = makeStyles((theme) => ({
  multiline: {
    whiteSpace: 'pre-line',
  },
  table_no_border: {
    '& th': {
      padding: '4px 5px',
      borderBottom: 'none',
      width: '40%',
    },
    '& td': {
      padding: '4px 5px',
      borderBottom: 'none',
      color: theme.palette.tertiary.main,
    },
    '& th, & th strong, & td': {
      lineHeight: 1,
    },
  },
}));

const DetailsTable = ({ demand, version, roles }) => {
  const classes = useStyles();
  const { t } = useTranslation('demands');
  const isDoctor = useSelector(isDoctorSelector);
  const isAssistant = useSelector(isAssistantSelector);

  return (
    <TableContainer>
      <Table className={classes.table_no_border}>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.reference')}</Typography>
            </TableCell>
            <TableCell>{demand.reference}</TableCell>
          </TableRow>
          {!version && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('fields.status')}</Typography>
              </TableCell>
              <TableCell>
                {demand.status &&
                  t(
                    `status.${getDemandStatus(
                      demand,
                      roles?.find(({ id }) => id === demand.assignedRoleId)?.permissions,
                    )}`,
                  )}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.doctor')}</Typography>
            </TableCell>
            <TableCell>{demand.doctor?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.demandDate')}</Typography>
            </TableCell>
            <TableCell>{format(new Date(demand.createdAt), 'dd/MM/yyyy')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.lastDemand')}</Typography>
            </TableCell>
            <TableCell>{format(new Date(demand.lastDemand), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.createdBy')}</Typography>
            </TableCell>
            <TableCell>{demand.createdBy?.name || '-'}</TableCell>
          </TableRow>
          {!isDoctor && !isAssistant && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('fields.company')}</Typography>
              </TableCell>
              <TableCell>{demand.company?.name}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.centerPrescriptor')}</Typography>
            </TableCell>
            <TableCell>{demand.centerPrescriptor?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.evolutivo')}</Typography>
            </TableCell>
            <TableCell>
              <CustomSwitch
                name="evolutivo"
                size="small"
                checked={version ? version.evolutivo : demand.evolutivo}
                disabled={true}
              />
            </TableCell>
          </TableRow>
          {!version && demand.evolutivo && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('fields.esquemaTratamientoPrevio')}</Typography>
              </TableCell>
              <TableCell className={classes.multiline}>{demand.esquemaTratamientoPrevio}</TableCell>
            </TableRow>
          )}
          {version?.evolutivo && (
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography component="strong">{t('fields.esquemaTratamientoPrevio')}</Typography>
              </TableCell>
              <TableCell>{version.esquemaTratamientoPrevio}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.centerRealizator')}</Typography>
            </TableCell>
            <TableCell>{demand.centerRealizator?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.observaciones')}</Typography>
            </TableCell>
            <TableCell className={classes.multiline}>
              {version ? version.observaciones : demand.observaciones}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.comentariosOficiales')}</Typography>
            </TableCell>
            <TableCell className={classes.multiline}>
              {version ? version.comentariosOficiales : demand.comentariosOficiales}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.decision_reason')}</Typography>
            </TableCell>
            <TableCell>{version ? version.decisionReason : demand.decisionReason}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Typography component="strong">{t('fields.final_reason')}</Typography>
            </TableCell>
            <TableCell>{version ? version.finalReason : demand.finalReason}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DetailsTable.propTypes = {
  demand: PropTypes.object,
  version: PropTypes.object,
  roles: PropTypes.array,
};

export default DetailsTable;
